import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index.js";
import { baseRoutes, syncRoutes } from "./routes";
import { ReqUserGetFuncScope } from "@/service/user";
import { Message } from "element-ui";
import { ReqEnumsList } from "@/service/enums";

Vue.use(Router);

// 授权路径
let permitPaths = [];

// 白名路径
const whiteList = ["/login", "/403", "/404", "/static"];

// 路由绑定
const createRouter = () =>
  new Router({
    //滚动条置顶
    scrollBehavior: () => ({ y: 0 }),
    routes: baseRoutes,
  });
const router = createRouter();

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export const resetRouter = () => {
  //重置路由(避免重复添加)
  router.matcher = createRouter().matcher;
};
export default router;

/**
 * 路由前置钩子 beforeEach
 * 1。补全面包屑
 * 2。通过权限构建目录结构
 */
router.beforeEach(async (to, from, next) => {
  // 全局当前组织
  store
    .dispatch(
      "sys/ChangeShow",
      [
        "/pool/income",
        "/pool/sales",
        "/collect/recognize/failure/record",
        "/collect/other/invoice",
        "/collect/list",
      ].indexOf(to.path) > -1
    )
    .then(() => {
      // console.log("sys/ChangeShow Success!");
    });

  /* 导航面包屑 */
  store.state.breadCrumbList = to.matched.reduce(
    (acc, crr) => [
      ...acc,
      {
        name: crr.name,
        disabled: crr.meta.disabled,
        path: crr.path,
      },
    ],
    []
  );
  store.state.breadCrumbList.shift(1);

  /* 刷新 重新构造目录树和权限列表 */
  if (baseRoutes.findIndex((r) => r.path == to.path) > -1) {
    next();
    return;
  }
  if (!store.state.routerLoaded) {
    try {
      const permitRoutes = await funcMenuTree();
      router.addRoutes(permitRoutes);
      store.state.routerLoaded = true;
      next({ path: to.path, query: to.query });
    } catch (e) {
      console.error("刷新路由并跳转时出错！", e);
    }
  } else {
    next();
  }
});

/**
 * 路由全局钩子 beforeResolve
 * 1。跳转权限校验
 */
router.beforeResolve((to, from, next) => {
  /* 是否登录 */
  if (to.path != "/login" && !localStorage.getItem("token")) {
    store.state.loginExpired = true;
    permitPaths = [];
    return;
  }
  /* 无权限路径回登录页面 */
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    /* 是否有跳转路径对应的权限 */
    if (permitPaths.length < 1) {
      next("/login");
    }
    if (permitPaths.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/403");
    }
  }
});

/**
 * 请求路由权限
 * @param resolve
 */
export const funcMenuTree = async () => {
  const res = await ReqUserGetFuncScope();
  if (!res.success || res.data == null || res.data.length < 1) {
    if (store.state.loginExpired) {
      Message({ message: "登录凭证已过期！", type: "error", duration: 10000 });
      const MODE = process.env.NODE_ENV;
      window.location.href = "/qdp" + (MODE == "development" ? ".html" : "");
    }
    return null;
  }
  /* 重置路由 */
  resetRouter();
  permitPaths = []; //List
  let permitRoutes = []; //Tree

  /* 路由授权 */
  const permitRouteCodes = res.data;
  const industry = store.state.user.industry;
  const codes = getRouteCodesByIndustry(permitRouteCodes, industry);
  getMenuTree(permitRoutes, syncRoutes, codes, industry);

  /* 缓存路由 */
  store.state.permission.roles = Array.from(new Set(permitRouteCodes));
  store.state.permission.funcList = permitRoutes;

  return [
    {
      path: "/layout",
      component: () => import("../Layout"),
      name: "layout",
      children: permitRoutes,
    },
  ];
};

/**
 * 根据行为返回路由编码
 * @param codes 原始路由编码
 * @returns {Array} 行业路由编码
 */
const getRouteCodesByIndustry = (codes, industry) => {
  let industryCodes = [];
  codes.forEach((item) => {
    industryCodes.push(`${industry}#${item}`);
  });
  return industryCodes;
};

/**
 * 构造目录树
 * @param permitRoutes
 * @param dependRoutes 路由资源集合
 * @param permitCodes 授权路由编码
 */
const getMenuTree = (
  permitRoutes = [],
  dependRoutes = [],
  permitCodes = [],
  industry = "HOTEL"
) => {
  for (let { ...r } of dependRoutes) {
    let code = r.meta.code;
    if (code.indexOf("#") < 0) {
      code = `${industry}#${code}`;
    }
    if (permitCodes.indexOf(code) > -1) {
      let children = [];
      if (r.children && r.children.length > 0) {
        getMenuTree(children, r.children, permitCodes, industry);
      }
      r.children = children;
      permitRoutes.push(r);
      permitPaths.push(r.path);
    }
  }
};
